<template>
  <div class="zone-parametres">
    <div class="partie-haute">
      <p class="titre-zone-parametres">
        Options visualisations
      </p>
      <ComposantParametreMultiselect
        header="Mode de visualisation"
        :value="parametresVisualisation.modeVisualisation"
        :options="optionsModeVisualisation"
        :paramRef="MODE_VISUALISATION"
        :updateParams="updateParams"
      />

      <div v-if="ifTypeVisualisation">
        <!-- TYPE_VISUALISATION -->
        <ComposantParametreMultiselect
          header="Données à visualiser"
          :value="parametresVisualisation.typeVisualisation"
          :options="optionsTypeVisualisation"
          :paramRef="TYPE_VISUALISATION"
          :updateParams="updateParams"
        />
      </div>

      <div v-if="ifChoixLieu">
        <!-- LIEU_A_VISUALISER -->
        <ComposantParametreMultiselect
          header="Lieu"
          :value="parametresVisualisation.lieuAVisualiser"
          :options="optionsLieuAVisualiser"
          :paramRef="LIEU_A_VISUALISER"
          :updateParams="updateParams"
        />
      </div>

      <!-- PERIODE_VISUALISATION -->
      <div v-if="ifPeriodeVisualisation">
        <div>
          <ComposantParametreMultiselect
            header="Période de visualisation"
            :value="parametresVisualisation.periodeVisualisation"
            :options="optionsPeriodeVisualisation"
            :paramRef="PERIODE_VISUALISATION"
            :updateParams="updateParams"
          />
        </div>
        <div
          v-if="ifPeriodeVisualisationDefinie"
          class="frame-input-choix-periode-visualisation"
        >
          <div class="frame-date-periode">
            <label for="input-date-debut">Du</label
            ><input
              type="date"
              id="input-date-debut"
              :value="parametresVisualisation[INPUT_DATE_DEPART]"
              @input="
                (e) => {
                  updateParams(INPUT_DATE_DEPART, e.target.value);
                }
              "
            />
          </div>
          <div class="frame-date-periode">
            <label for="input-date-fin">Au</label
            ><input
              type="date"
              id="input-date-fin"
              :value="parametresVisualisation[INPUT_DATE_FIN]"
              @input="
                (e) => {
                  updateParams(INPUT_DATE_FIN, e.target.value);
                }
              "
            />
          </div>
        </div>
        <div
          v-if="ifPeriodeVisualisationPremiersMois"
          class="frame-input-choix-periode-visualisation"
        >
          <div class="frame-date-periode">
            <label>Les</label
            ><input
              type="number"
              min="0"
              class="input-premiers-mois"
              :value="parametresVisualisation[INPUT_PREMIERS_MOIS]"
              @input="
                (e) => {
                  updateParams(INPUT_PREMIERS_MOIS, e.target.value);
                }
              "
            />
            <label>premiers mois</label>
          </div>
        </div>
      </div>

      <div v-if="ifTypeClassement">
        <!-- TYPE_CLASSEMENT -->
        <ComposantParametreMultiselect
          header="Classement par"
          :value="parametresVisualisation.typeClassement"
          :options="optionsTypeClassement"
          :paramRef="TYPE_CLASSEMENT"
          :updateParams="updateParams"
        />

        <!-- ORDRE_CLASSEMENT -->
        <ComposantParametreMultiselect
          header="Afficher par"
          :value="parametresVisualisation.ordreClassement"
          :options="optionsOrdreClassement"
          :paramRef="ORDRE_CLASSEMENT"
          :updateParams="updateParams"
        />
        <!-- FILTRE QUANTITE -->
        <ComposantParametreMultiselect
          header="Afficher uniquement"
          :value="parametresVisualisation.filtreSuperieurOuInferieur"
          :options="optionsSuperieurOuInferieur"
          :paramRef="FILTRE_SUPERIEUR_OU_INFERIEUR"
          :updateParams="updateParams"
          :addInput="true"
          :inputValue="parametresVisualisation[INPUT_QUANTITE_FILTRE]"
          :inputParamRef="INPUT_QUANTITE_FILTRE"
        />

        <div v-if="ifClassementRupture">
          <ComposantCheckbox
            class="checkbox"
            label="Masquer les valeurs négatives"
            @update="
              (value) => updateParams(CHECKBOX_MASQUER_VALEURS_NEGATIVES, value)
            "
          />
        </div>

        <div v-if="ifClassementRentabilite">
          <ComposantCheckbox
            class="checkbox"
            label="Afficher numéros rentables"
            @update="
              (value) => updateParams(CHECKBOX_AFFICHER_RENTABLES, value)
            "
          />
          <ComposantCheckbox
            class="checkbox"
            label="Afficher numéros non rentables"
            @update="
              (value) => updateParams(CHECKBOX_AFFICHER_NON_RENTABLES, value)
            "
          />
        </div>
      </div>
      <div v-if="proposerChoixNumeros">
        <div class="label">
          Choix des numéros
        </div>
        <!-- :value="listeIdsNumerosAVisualiser" -->
        <multiselect
          :value="parametresVisualisation.listeNumerosAVisualiser"
          :customLabel="
            (numero) => `${numero.numero_numero} - ${numero.titre_numero}`
          "
          track-by="id_numero"
          :options="this.parametresVisualisation.listeNumerosTriee"
          @open="checkListeNumerosTriee"
          @input="
            (option) => {
              updateParams(LISTE_NUMEROS_A_VISUALISER, option);
            }
          "
          :close-on-select="
            parametresVisualisation.modeVisualisation.value !=
              MODE_VISUALISATION_MULTIPLE
          "
          :clear-on-select="
            parametresVisualisation.modeVisualisation.value !=
              MODE_VISUALISATION_MULTIPLE
          "
          :multiple="
            parametresVisualisation.modeVisualisation.value ==
              MODE_VISUALISATION_MULTIPLE
          "
          :showLabels="false"
          :searchable="!screenIsPhone"
        ></multiselect>
      </div>
      <div v-if="ifChoixFestival">
        <ComposantParametreMultiselect
          header="Festival"
          :value="parametresVisualisation.festivalAVisualiser"
          :options="optionsFestivalAVisualier"
          :paramRef="FESTIVAL_A_VISUALISER"
          :updateParams="updateParams"
        />
      </div>
      <div v-if="afficherChoixPeriodeProjection">
        <div class="frame-date-periode">
          <label>Projection sur </label
          ><input
            type="number"
            min="0"
            class="input-premiers-mois"
            :value="parametresVisualisation[INPUT_DUREE_PROJECTION]"
            @input="
              (e) => {
                updateParams(INPUT_DUREE_PROJECTION, parseInt(e.target.value));
              }
            "
          />
          <label> mois</label>
        </div>
      </div>
      <div v-if="ifCheckBoxesPackEtHS">
        <ComposantCheckbox
          class="checkbox"
          label="Inclure les packs (coffrets)"
          @update="(value) => updateParams(CHECKBOX_AFFICHER_PACK, value)"
        />
        <ComposantCheckbox
          class="checkbox"
          label="Inclure les numéros Hors Série"
          @update="(value) => updateParams(CHECKBOX_AFFICHER_HORS_SERIE, value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as listeConstantes from "../js/globalConst";

function defaultFunctionFactory(message) {
  return {
    type: Function,
    default: function() {
      console.error(message);
    },
  };
}

let optionsTypeVisualisation = [
  { value: listeConstantes.TYPE_VISUALISATION_STOCK, label: "Stock" },
  { value: listeConstantes.TYPE_VISUALISATION_VENTES, label: "Ventes" },
];
let optionsPeriodeVisualisation = [
  { value: listeConstantes.PERIODE_VISUALISATION_ENTIERE, label: "Entiere" },
  { value: listeConstantes.PERIODE_VISUALISATION_DEFINIE, label: "Définie" },
  {
    value: listeConstantes.PERIODE_VISUALISATION_PREMIERS_MOIS,
    label: "Premiers mois",
  },
];
let optionsModeVisualisation = [
  // {
  //   label: "Afficher numero unique",
  //   value: listeConstantes.MODE_VISUALISATION_SOLO,
  // },
  {
    label: "Stock / Ventes par numéro",
    value: listeConstantes.MODE_VISUALISATION_MULTIPLE,
  },
  {
    label: "Statistiques globales",
    value: listeConstantes.MODE_VISUALISATION_GLOBAL,
  },
  {
    label: "Classement numéros",
    value: listeConstantes.MODE_VISUALISATION_CLASSEMENT,
  },
  {
    label: "Statistiques Festivals",
    value: listeConstantes.MODE_VISUALISATION_FESTIVAL,
  },
  {
    label: "Finances",
    value: listeConstantes.MODE_VISUALISATION_FINANCES,
  },
  {
    label: "Projections",
    value: listeConstantes.MODE_VISUALISATION_PROJECTIONS,
  },
];
let optionsTypeClassement = [
  { value: listeConstantes.TYPE_CLASSEMENT_STOCK, label: "Stock" },
  { value: listeConstantes.TYPE_CLASSEMENT_VENTES, label: "Ventes" },
  { value: listeConstantes.TYPE_CLASSEMENT_VITESSE, label: "Vitesse de vente" },
  {
    value: listeConstantes.TYPE_CLASSEMENT_RUPTURE_DE_STOCK,
    label: "Rupture de stock",
  },
  {
    value: listeConstantes.TYPE_CLASSEMENT_RENTABILITE,
    label: "Rentabilité",
  },
  // {
  //   value: listeConstantes.TYPE_CLASSEMENT_TEST,
  //   label: "Test",
  // },
];
let optionsOrdreClassement = [
  { value: listeConstantes.ORDRE_CROISSANT, label: "Ordre croissant" },
  { value: listeConstantes.ORDRE_DECROISSANT, label: "Ordre décroissant" },
  { value: listeConstantes.ORDRE_CHRONOLOGIQUE, label: "Date de sortie" },
];
let optionsSuperieurOuInferieur = [
  { label: "Indifférent", value: listeConstantes.FILTRE_INDIFFERENT },
  { label: "égal à", value: listeConstantes.FILTRE_EGALITE },
  { label: "supérieur à", value: listeConstantes.FILTRE_SUPERIEUR },
  { label: "inférieur à", value: listeConstantes.FILTRE_INFERIEUR },
];
let listeOptions = {
  optionsTypeVisualisation,
  optionsPeriodeVisualisation,
  optionsModeVisualisation,
  optionsTypeClassement,
  optionsOrdreClassement,
  optionsSuperieurOuInferieur,
};

export default {
  name: "ComposantParametresStatistiques",
  props: {
    parametresVisualisation: { default: "" },
    updateParams: defaultFunctionFactory(
      "Fonction d'update des paramètres manquante"
    ),
    checkListeNumerosTriee: defaultFunctionFactory(
      "Aucune fonction de vérification de liste transmise"
    ),
  },
  components: {
    // return {
    ComposantParametreMultiselect: () =>
      // listeConstantes
      import("./composantParametreMultiselect"),
  },
  data: () => {
    return {
      ...listeConstantes,
      ...listeOptions,
      optionsLieuAVisualiser: [
        {
          label: "Tous lieux confondus",
          value: listeConstantes.TOUS_LIEUX_CONFONDUS,
        },
      ],
      optionsFestivalAVisualier: [
        {
          label: "Choisir festival",
          value: listeConstantes.AUCUN_FESTIVAL_CHOISI,
        },
      ],
      listeNumerosTemp: undefined,
    };
  },
  computed: {
    ...mapState(["screenIsPhone", "listeLieux"]),
    proposerChoixNumeros() {
      if (this.parametresVisualisation == undefined) return false;
      let listeOptions = [
        this.MODE_VISUALISATION_SOLO,
        this.MODE_VISUALISATION_MULTIPLE,
      ];

      return listeOptions.includes(
        this.parametresVisualisation.modeVisualisation.value
      );
    },
    afficherChoixPeriodeProjection() {
      return (
        this.parametresVisualisation.modeVisualisation.value ==
        this.MODE_VISUALISATION_PROJECTIONS
      );
    },
    ifTypeVisualisation() {
      let listeOptions = [
        this.MODE_VISUALISATION_GLOBAL,
        this.MODE_VISUALISATION_SOLO,
        this.MODE_VISUALISATION_MULTIPLE,
      ];
      return listeOptions.includes(
        this.parametresVisualisation.modeVisualisation.value
      );
    },
    ifPeriodeVisualisation() {
      let listeOptions = [
        this.MODE_VISUALISATION_SOLO,
        this.MODE_VISUALISATION_MULTIPLE,
      ];
      return listeOptions.includes(
        this.parametresVisualisation.modeVisualisation.value
      );
    },
    ifClassementRupture() {
      return (
        this.parametresVisualisation.typeClassement.value ==
        this.TYPE_CLASSEMENT_RUPTURE_DE_STOCK
      );
    },
    ifClassementRentabilite() {
      return (
        this.parametresVisualisation.typeClassement.value ==
        this.TYPE_CLASSEMENT_RENTABILITE
      );
    },
    ifTypeClassement() {
      return (
        this.parametresVisualisation.modeVisualisation.value ==
        this.MODE_VISUALISATION_CLASSEMENT
      );
    },
    ifPeriodeVisualisationDefinie() {
      return (
        this.parametresVisualisation.periodeVisualisation.value ==
        this.PERIODE_VISUALISATION_DEFINIE
      );
    },
    ifPeriodeVisualisationPremiersMois() {
      return (
        this.parametresVisualisation.periodeVisualisation.value ==
        this.PERIODE_VISUALISATION_PREMIERS_MOIS
      );
    },
    ifChoixLieu() {
      return false;
      // return (
      //   this.parametresVisualisation.modeVisualisation.value ==
      //   this.MODE_VISUALISATION_GLOBAL
      // );
    },
    ifChoixFestival() {
      return (
        this.parametresVisualisation.modeVisualisation.value ==
        this.MODE_VISUALISATION_FESTIVAL
      );
    },
    ifCheckBoxesPackEtHS() {
      return (
        this.parametresVisualisation.typeClassement.value !=
          this.TYPE_CLASSEMENT_RENTABILITE &&
        this.parametresVisualisation.modeVisualisation.value !=
          this.MODE_VISUALISATION_FESTIVAL &&
        this.parametresVisualisation.modeVisualisation.value !=
          this.MODE_VISUALISATION_FINANCES &&
        this.parametresVisualisation.modeVisualisation.value !=
          this.MODE_VISUALISATION_PROJECTIONS
      );
    },
  },
  methods: {
    ...mapActions(["consoleMessage", "phpPost"]),
    updateListeFestivals() {
      this.phpPost({
        phpFile: "assets/php/listeFestivals.php",
        disableThumbnail: true,
        callBack: (response) => {
          this.consoleMessage({ response });
          if (response.reqSuccess)
            this.optionsFestivalAVisualier = [
              ...this.optionsFestivalAVisualier,
              ...response.listeFestivals.map((festival) => {
                let date = festival.date_festival.split("-");
                date.reverse();
                date = date.join("/");
                return {
                  label: `${festival.nom_festival} (${date})`,
                  value: festival,
                };
              }),
            ];
          response.listeFestivals;
        },
      });
    },
    updateListeLieux() {
      if (this.listeLieux != undefined) {
        for (let lieu of this.listeLieux) {
          this.optionsLieuAVisualiser = [
            ...this.optionsLieuAVisualiser,
            { label: lieu.nom_lieu, value: lieu.id_lieu },
          ];
        }
      }
    },
  },
  watch: {
    listeLieux() {
      this.updateListeLieux();
    },
  },
  mounted: function() {
    this.updateListeLieux();
    this.updateListeFestivals();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";

$marge-titre: 10pdx;
$title-font-size: 25px;

.multiselect {
  cursor: pointer;
}

.zone-parametres-width {
  width: 340px;
}

.zone-parametres {
  text-align: center;
  @include typo-festival;
  background-color: $couleur-fond-menu-navigation;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
  border: 1px solid $couleur-texte-clair;
  padding: 20px;
  border-radius: 10px;
  overflow: auto;
  width: 300px;
  // height: 90vh;
  @media (max-width: $mobile-break-point) {
    z-index: 10;
    display: block;
    position: fixed;
    top: 60px;
    margin: auto;
    max-width: none;
    min-width: none;
    width: 100%;
    height: 90vh;
  }
  // border: 3px solid black;
  // height: 80vh;
}

.checkbox {
  margin-top: 10px;
}

.label {
  text-align: left;
  @include typo-festival;
  margin-left: 5px;
  margin-top: 15px;
}

.partie-haute {
  display: flex;
  // border: 1px solid black;
  height: 100%;
  flex-direction: column;
}

.titre-zone-parametres {
  margin-bottom: $marge-titre;
  font-size: $title-font-size;
  display: flex;
  justify-content: center;
  // margin-bottom: 10px;
}

.frame-input-choix-periode-visualisation {
  display: flex;
  flex-direction: column;
}

.frame-date-periode {
  // border: 1px solid #000;
  & label {
    position: relative;
    top: 3px;
  }
  & input {
    margin: 0 6px;
  }
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding-left: 8px;
  margin-top: 5px;
}

.input-premiers-mois {
  width: 40px;
}

.close-cross {
  display: none;
  // border: 1px solid #000;
  @media (max-width: $mobile-break-point) {
    display: flex;
  }
  justify-content: center;
  font-size: 2.5em;
  width: 50px;
  position: fixed;
  bottom: 10px;
  background: rgba(122, 122, 122, 0.1);
  left: calc(50% - 25px);
  border-radius: 50%;
}
.select-options {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}

.liste-numeros {
  @include typo-festival;
  width: 100%;
  height: 100%;
  @media (max-width: $mobile-break-point) {
    height: 50px;
  }
}

.frame-type-classement {
  // border: 1px solid #000;
  text-align: left;
  display: flex;
  width: 100%;
  align-items: center;
  & label {
    width: 100%;
  }
}

.multiselect-filtre {
  width: 150px;
}

.input-filtre {
  width: 70px;
}

.notice-mobile {
  display: none;
  @media (max-width: $mobile-break-point) {
    display: flex;
  }
}
.icone-info-mobile {
  @include typo-festival;
  font-weight: bold;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid $couleur-texte-fonce;
}
</style>
